import React from "react";
import { Router } from "@reach/router";
import TeamPage from "../components/TeamPage";
import NotFoundPage from "./404";

const teamPageRouter = (props) => (
  <Router>
    <TeamPage {...props} path="/team/" />
    <NotFoundPage default />
  </Router>
);

export default teamPageRouter;
