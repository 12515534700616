import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const TeamContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allTeamJson {
          edges {
            node {
              id
              pageTitle
              heroBannerSection {
                bannerImage
                heading
                content
                actionButton {
                  buttonLabel
                  buttonPath
                }
              }
              hideMembers
              cardSection {
                hideMember
                cardImage
                cardSubTitle
                cardTitle
                cardButtons {
                  linkedIn {
                    buttonPath
                  }
                  twitter {
                    buttonPath
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ allTeamJson: { edges: TeamData } }) =>
      children(
        TeamData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

TeamContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default TeamContainer;
