import React, { useContext } from "react";
import { camelCase } from "change-case";
import { ThemeContext } from "styled-components";
import Box from "../../base/Box";
import SEO from "../SEO/seo";
import Heading from "../Heading";
import Section from "../Section";
import AspectRatio from "../AspectRatio";
import BgImage from "../BgImage";
import TeamContainer from "../../containers/TeamContainer";
import Image from "../Image";
import LinkTo from "../LinkTo";
import PageHeroBanner from "../PageHeroBanner";

const TeamPage = () => {
  const setSection = (sectionDetails) => {
    const { cardSection, heroBannerSection, hideMembers } = sectionDetails;
    const theme = useContext(ThemeContext);
    return (
      <>
        {heroBannerSection && (
          <PageHeroBanner
            actionButton={heroBannerSection.actionButton}
            bannerImage={heroBannerSection.bannerImage}
            content={{
              heading: heroBannerSection.heading,
              text: heroBannerSection.content,
            }}
          />
        )}

        {cardSection && cardSection.length > 0 && !hideMembers && (
          <Section
            sx={{
              bg: "white",
            }}
          >
            <Box
              sx={{
                mb: [2, null, null, 5],
                textAlign: ["center", null, null, "left"],
              }}
              variant="markdown.h2"
            >
              Our team
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                mx: [-2, null, null, -4],
              }}
            >
              {cardSection.map((cardItem, index) => {
                const isLast = cardSection.length === index + 1;
                const { cardImage, cardSubTitle, cardTitle, cardButtons } =
                  cardItem;
                return (
                  cardItem &&
                  !cardItem.hideMember && (
                    <Box
                      sx={{
                        my: [3, null, null, null],
                        px: [2, null, null, 4],
                        width: ["100%", null, null, "calc(100% / 2)"],
                      }}
                    >
                      <Box
                        key={`${cardTitle}_${index}`}
                        sx={{
                          ...(isLast ? theme.designElement.rightBottom : {}),
                          bg: "graySubtle",
                          boxShadow: 5,
                          display: "flex",
                          flexWrap: "wrap",
                          px: 2,
                          py: [2, null, null, 3],
                        }}
                      >
                        <Box
                          sx={{
                            p: 3,
                            width: ["100%", null, null, "calc(100% / 2)"],
                          }}
                        >
                          {cardImage && (
                            <AspectRatio
                              ratio={[1, 1]}
                              sx={{
                                mx: "auto",
                                overflow: "hidden",
                                width: "100%",
                              }}
                            >
                              <BgImage
                                name={cardImage}
                                page="team"
                                sx={{
                                  backgroundPosition: "inherit",
                                  backgroundSize: "cover",
                                  position: "absolute",
                                }}
                              />
                            </AspectRatio>
                          )}
                        </Box>
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: [
                              "center",
                              "center",
                              "center",
                              "left",
                            ],
                            p: 3,
                            width: ["100%", null, null, "calc(100% / 2)"],
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {cardTitle && (
                              <Heading
                                sx={{
                                  color: "yellowDark",
                                  fontSize: 3,
                                  pb: 1,
                                  textAlign: ["center", null, null, "left"],
                                }}
                              >
                                {cardTitle}
                              </Heading>
                            )}
                            {cardSubTitle && (
                              <Box
                                sx={{
                                  color: "text.primary",
                                  fontSize: 2,
                                  fontWeight: "light",
                                  pb: 1,
                                  textAlign: ["center", null, null, "left"],
                                }}
                              >
                                {cardSubTitle}
                              </Box>
                            )}
                            {cardButtons && (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  justifyContent: [
                                    "center",
                                    null,
                                    null,
                                    "left",
                                  ],
                                }}
                              >
                                {cardButtons.twitter &&
                                  cardButtons.twitter.buttonPath && (
                                    <LinkTo
                                      key={`${camelCase("twitter")}-${index}`}
                                      isLink={cardButtons.twitter.buttonPath}
                                      sx={{
                                        "&:after": {
                                          display: "none !important",
                                        },
                                        "&:not(:hover)": {
                                          svg: {
                                            fill: "grays.6",
                                          },
                                        },
                                        mx: 1,
                                      }}
                                      target="_blank"
                                    >
                                      <Image
                                        svgIcon="twitter"
                                        sx={{
                                          height: "2.5rem",
                                          width: "2.5rem",
                                        }}
                                      />
                                    </LinkTo>
                                  )}
                                {cardButtons.linkedIn &&
                                  cardButtons.linkedIn.buttonPath && (
                                    <LinkTo
                                      key={`${camelCase("linkedIn")}-${index}`}
                                      isLink={cardButtons.linkedIn.buttonPath}
                                      sx={{
                                        "&:after": {
                                          display: "none !important",
                                        },
                                        "&:not(:hover)": {
                                          svg: {
                                            fill: "grays.6",
                                          },
                                        },
                                      }}
                                      target="_blank"
                                    >
                                      <Image
                                        svgIcon="linkedIn"
                                        sx={{
                                          height: "2.5rem",
                                          width: "2.5rem",
                                        }}
                                      />
                                    </LinkTo>
                                  )}
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )
                );
              })}
            </Box>
          </Section>
        )}
      </>
    );
  };
  return (
    <TeamContainer>
      {(newsData) => {
        const pageId = "team";
        const newsInfo = newsData.find((data) => data.id === pageId);

        return (
          <>
            <SEO pathname="team" templateTitle={newsInfo.pageTitle} />
            {setSection(newsInfo)}
          </>
        );
      }}
    </TeamContainer>
  );
};

TeamPage.propTypes = {};

export default TeamPage;
